import { CSVDownload, CSVLink } from 'react-csv';
import {
  Checkbox,
  DescriptionList,
  Icon,
  Modal,
  Page,
  Tooltip
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { authState, ordersState, toastState } from '../atoms';
import { formatCurrency, getTrackingUrl, request } from '../utils';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AuthInfo } from '../types/AuthInfo';
import DropKickTitleBar from '../components/DropKickTitleBar';
import { DynamoOrder } from '../types/DynamoOrder';
import LoadingSkeletonTable from '../components/LoadingSkeletonTable';
import NoRecordsFound from '../components/NoRecordsFound';
import { PageChangeType } from '../types/Pagination';
import { PageDownMajor } from '@shopify/polaris-icons';
import Pagination from '../components/Pagination';
import { ShopifyHeaders } from '../types/ShopifyHeaders';
import StatusBadge from '../components/StatusBadge';
import { TableState } from '../types/TableState';
import axios from 'axios';
import { loadConfig } from '../config';
import styled from 'styled-components';

const config = loadConfig();

const ModalStyles = styled.div`
  .Polaris-Choice__Label {
    line-height: 1rem;
  }
  label.Polaris-Choice {
    align-items: center !important;
  }
`;

const STC_FIXED_SHIPPING_COST = 7.99;

const Orders: React.FC = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const authInfo: AuthInfo = useRecoilValue(authState);
  const [, setToast] = useRecoilState(toastState);
  const [ordersInfo, setOrdersInfo]: [TableState, any] =
    useRecoilState(ordersState);
  const [refreshCount, setRefreshCount] = useState(0);

  const [modalActive, setModalActive] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [selectedShopDomain, setSelectedShopDomain] = useState('');
  const [skipEmailNotifications, setSkipEmailNotifications] = useState(true);
  const [skipStoreOwnerCharge, setSkipStoreOwnerCharge] = useState(false);
  const [skipFulfillment, setSkipFulfillment] = useState(false);

  // manual fulfillment modal
  const [manuallyFulfilledModalActive, setManuallyFulfilledModalActive] =
    useState(false);
  const [manuallyFulfilledModalLoading, setManuallyFulfilledModalLoading] =
    useState(false);

  const handleReprocess = useCallback(async () => {
    let result: any = null;
    try {
      setModalLoading(true);
      const body = {
        id: selectedOrderId,
        test: false,
        force: true,
        skipEmailNotifications: skipEmailNotifications,
        skipStoreOwnerCharge: skipStoreOwnerCharge,
        skipFulfillment: skipFulfillment,
        fixMissingMetadata: false
      };
      const url = `https://${config.shopify.apiUrl}/v1/orders_create`;
      result = await axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          [ShopifyHeaders.accessToken]: authInfo.auth,
          [ShopifyHeaders.domain]: selectedShopDomain,
          [ShopifyHeaders.hmac]: selectedShopDomain
        }
      });

      console.log('result', result.data);
      setToast({
        active: true,
        message: 'Order reprocessed successfully!',
        type: 'success'
      });
    } catch (e: any) {
      console.error('error reprocessing order', e);
      console.error('result', result);
      setToast({
        active: true,
        message: 'Error Reprocessing: ' + e.message,
        type: 'error'
      });
    } finally {
      setModalLoading(false);
    }
  }, [
    selectedOrderId,
    skipEmailNotifications,
    skipStoreOwnerCharge,
    skipFulfillment,
    authInfo.auth,
    selectedShopDomain,
    setToast
  ]);

  const handleManualComplete = useCallback(async () => {
    let result: any = null;
    try {
      setManuallyFulfilledModalLoading(true);
      const body = {
        id: selectedOrderId,
        test: false,
        force: true,
        markAsManuallyFulfilled: true
      };
      const url = `https://${config.shopify.apiUrl}/v1/orders_create`;
      result = await axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          [ShopifyHeaders.accessToken]: authInfo.auth,
          [ShopifyHeaders.domain]: selectedShopDomain,
          [ShopifyHeaders.hmac]: selectedShopDomain
        }
      });

      console.log('result', result.data);
      setToast({
        active: true,
        message: 'Order marked as complete successfully!',
        type: 'success'
      });
    } catch (e: any) {
      console.error('error reprocessing order', e);
      console.error('result', result);
      setToast({
        active: true,
        message: 'Error marking complete: ' + e.message,
        type: 'error'
      });
    } finally {
      setManuallyFulfilledModalLoading(false);
    }
  }, [
    selectedOrderId,
    skipEmailNotifications,
    skipStoreOwnerCharge,
    skipFulfillment,
    authInfo.auth,
    selectedShopDomain,
    setToast
  ]);

  // make request to get data
  const getOrders = useCallback(
    async (force: boolean) => {
      setLoading(true);
      // console.log('getting ordersInfo', ordersInfo.lastEvaluatedKey);
      const result = await request('POST', 'orders', authInfo.auth, {
        pageSize: ordersInfo.pageSize,
        search: ordersInfo.search,
        exclusiveStartKey: ordersInfo.lastEvaluatedKey
      });
      if (!result) {
        setToast({
          active: true,
          message: 'Error getting orders',
          type: 'error'
        });
        setLoading(false);
        return;
      }
      const newOrdersInfo = {
        ...ordersInfo,
        items: result.items,
        lastEvaluatedKey: result.lastEvaluatedKey,
        lastFetched: new Date().getTime()
      };
      console.log('got ordersInfo', newOrdersInfo);
      setOrdersInfo(newOrdersInfo);
      setLoading(false);
    },
    [
      authInfo.auth,
      ordersInfo.pageSize,
      ordersInfo.exclusiveStartKey,
      ordersInfo.search,
      setOrdersInfo,
      refreshCount,
      setToast
    ]
  );

  // export data to csv
  const getCsvData = useCallback(() => {
    const retval: any[] = [
      'Order ID',
      'Store Owner Charge',
      'Store Owner Shipping',
      'Our Cost',
      'STC Shipping Cost'
    ];
    if (ordersInfo.items) {
      for (const order of ordersInfo.items) {
        retval.push([
          order.id,
          parseFloat(order.orderAmount + ''),
          parseFloat((order.shippingFee || 0) + ''),
          parseFloat(order.productCosts + '') +
            parseFloat(order.fulfillmentFee + ''),
          STC_FIXED_SHIPPING_COST
        ]);
      }
    }
    return retval;
  }, [ordersInfo]);

  const handlePageSizeChange = (newSize) => {
    console.log('changing page size to', newSize);
    setOrdersInfo({
      ...ordersInfo,
      prevExclusiveStartKeys: [],
      lastEvaluatedKey: null,
      exclusiveStartKey: null,
      pageSize: newSize
    });
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      setOrdersInfo({
        ...ordersInfo,
        prevExclusiveStartKeys: [],
        lastEvaluatedKey: null,
        exclusiveStartKey: null,
        search: e.target.value
      });
    }
  };

  const handleChangePage = (type: PageChangeType) => {
    switch (type) {
      case 'next':
        console.log(
          'changing exclusiveStartKey to',
          ordersInfo.lastEvaluatedKey
        );
        setOrdersInfo({
          ...ordersInfo,
          exclusiveStartKey: ordersInfo.lastEvaluatedKey,
          prevExclusiveStartKeys: ordersInfo.exclusiveStartKey
            ? [...ordersInfo.prevExclusiveStartKeys].concat([
                ordersInfo.exclusiveStartKey
              ])
            : []
        });
        break;
      case 'prev':
        if (ordersInfo.prevExclusiveStartKeys.length > 0) {
          setOrdersInfo({
            ...ordersInfo,
            exclusiveStartKey:
              ordersInfo.prevExclusiveStartKeys[
                ordersInfo.prevExclusiveStartKeys.length - 1
              ],
            lastEvaluatedKey:
              ordersInfo.prevExclusiveStartKeys[
                ordersInfo.prevExclusiveStartKeys.length - 1
              ],
            prevExclusiveStartKeys: [
              ...ordersInfo.prevExclusiveStartKeys
            ].slice(0, -1)
          });
        } else {
          setOrdersInfo({
            ...ordersInfo,
            exclusiveStartKey: null,
            lastEvaluatedKey: null,
            prevExclusiveStartKeys: []
          });
        }

        break;
    }
  };

  const handleRefresh = () => {
    // reset to first page
    setOrdersInfo({
      ...ordersInfo,
      prevExclusiveStartKeys: [],
      lastEvaluatedKey: null,
      exclusiveStartKey: null
    });
    setRefreshCount(refreshCount + 1);
  };

  const trackingNumberMarkup = (order: any) => {
    return (
      <>
        {order.trackingNumber && order.trackingNumber.indexOf(',') < 0 && (
          <Tooltip
            content={<div className="text-xs">{order.trackingNumber}</div>}
            dismissOnMouseOut={true}
          >
            <div className="hover:underline hover:cursor-pointer text-blue-500">
              <a
                href={getTrackingUrl(order.trackingNumber)}
                target="_blank"
                className="text-blue-500 font-medium"
                rel="noreferrer"
              >
                View Tracking
              </a>
            </div>
          </Tooltip>
        )}
        {order.trackingNumber &&
          order.trackingNumber.indexOf(',') >= 0 &&
          order.trackingNumber.split(',').map((trackingNumber, i) => {
            return (
              <div>
                <Tooltip
                  content={<div className="text-xs">{trackingNumber}</div>}
                  dismissOnMouseOut={true}
                >
                  <div className="hover:underline hover:cursor-pointer text-blue-500">
                    <a
                      href={getTrackingUrl(trackingNumber)}
                      target="_blank"
                      className="text-blue-500 font-medium"
                      rel="noreferrer"
                    >
                      View Tracking #{i + 1}
                    </a>
                  </div>
                </Tooltip>
              </div>
            );
          })}
      </>
    );
  };

  // get orders from server or cache
  useEffect(() => {
    getOrders(false);
  }, [getOrders]);

  function calculateNimbleStackProfit(order: DynamoOrder) {
    if (order.shopDomain.indexOf('adultfanshop') >= 0) {
      return order.orderAmount && order.stcActualTotal
        ? formatCurrency(
            parseFloat(order.orderAmount + '') -
              parseFloat(order.stcActualTotal + '')
          )
        : '';
    }

    // normal
    return order.totalCharge && order.stcActualTotal
      ? formatCurrency(
          parseFloat(order.totalCharge + '') -
            parseFloat(order.stcActualTotal + '')
        )
      : '';
  }

  return (
    <>
      <DropKickTitleBar title="Orders" />
      <Page
        title="Orders"
        titleHidden={true}
        fullWidth={true}
        subtitle={
          'This page displays all orders involving products imported from the product catalog.'
        }
      >
        <div className="mt-6 mb-8 sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {/* <CSVLink
              data={[
                [
                  'Order Date',
                  'Order ID',
                  'Order Amount',
                  'Store Owner Charge',
                  'Store Owner Shipping',
                  'Our Cost',
                  'STC Shipping Cost'
                ]
              ].concat(
                ordersInfo.items
                  ? ordersInfo.items.map((order) => {
                      const ourCosts =
                        parseFloat(order.productCosts + '') +
                        parseFloat(
                          order.fulfillmentFee + STC_FIXED_SHIPPING_COST
                        );
                      return [
                        new Date(order.created).toLocaleDateString(),
                        order.SK.split('#')[1],
                        0,
                        parseFloat(order.orderAmount + ''),
                        parseFloat(order.orderAmount + ''),
                        ourCosts,
                        parseFloat((order.shippingFee || 0) + ''),
                        STC_FIXED_SHIPPING_COST
                      ] as any;
                    })
                  : [
                      'No orders found',
                      'No orders found',
                      'No orders found',
                      'No orders found',
                      'No orders found',
                      'No orders found'
                    ]
              )}
              filename={'orders.csv'}
              target="_blank">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white p-2 mr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto">
                <Icon source={PageDownMajor} color="base" />
              </button>
            </CSVLink> */}
            <button
              type="button"
              onClick={handleRefresh}
              className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white p-2 text-sm  font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </button>
          </div>
        </div>
        {loading ? (
          <LoadingSkeletonTable />
        ) : (
          <>
            <div className="mt-8 mb-16 flex flex-col">
              <div className="-my-2 -mx-4 overflow-visible sm:-mx-6 lg:-mx-8">
                <div className=" inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 pb-200">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <Pagination
                      entityInfo={ordersInfo}
                      handleKeyUp={handleKeyUp}
                      handlePageSizeChange={handlePageSizeChange}
                      handleChangePage={handleChangePage}
                      location="header"
                    />
                    <table className="min-w-full divide-y divide-gray-300 z-0">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Order Number
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Created Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-right text-sm font-semibold sm:pr-6 text-gray-900"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-right text-sm font-semibold sm:pr-6 text-gray-900"
                          >
                            Costs
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-right text-sm font-semibold sm:pr-6 text-gray-900"
                          >
                            Profit
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Tracking
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Notes
                          </th>
                          {authInfo.auth.admin && (
                            <>
                              <th
                                scope="col"
                                className="px-3 py-3.5 bg-yellow-50 text-left text-sm font-semibold text-gray-900"
                              >
                                Profit
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 bg-yellow-50 text-left text-sm font-semibold text-gray-900"
                              >
                                Shop Info
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 bg-yellow-50 text-left text-sm font-semibold text-gray-900"
                              >
                                Status Page
                              </th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {ordersInfo.items &&
                          ordersInfo.items.map(
                            (order: DynamoOrder, ordersIndex) => {
                              // split sk
                              const orderId = order.SK.split('#')[1];
                              const profit =
                                parseFloat(order.orderAmount + '') -
                                parseFloat(order.totalCharge + '');
                              const dkProfit =
                                calculateNimbleStackProfit(order);

                              const created = new Date(order.created);
                              return (
                                <tr
                                  key={'order-' + ordersIndex}
                                  className={
                                    'hover:bg-gray-100 ' +
                                    (ordersIndex % 2 === 0
                                      ? undefined
                                      : 'bg-gray-50')
                                  }
                                >
                                  <td className="whitespace-nowrap py-4 pl-6 font-medium hover:underline text-sm sm:pr-6 text-blue-500">
                                    <a
                                      href={`https://${order.shopDomain}/admin/orders/${orderId}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {order.orderName}
                                    </a>
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-3 text-sm sm:pr-6 text-gray-500">
                                    <span>
                                      {created.toLocaleDateString() +
                                        ' ' +
                                        created.toLocaleTimeString()}
                                    </span>
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-3 text-right text-sm sm:pr-6 text-gray-500">
                                    <div>
                                      {formatCurrency(
                                        parseFloat(order.orderAmount + '')
                                      )}
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-3 text-right text-sm sm:pr-6 text-gray-500">
                                    <Tooltip
                                      content={
                                        <div>
                                          <table>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <strong>Product Costs</strong>
                                                </td>
                                                <td className="text-right p-1">
                                                  {formatCurrency(
                                                    -1 *
                                                      parseFloat(
                                                        order.productCosts + ''
                                                      )
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <strong>
                                                    Fulfillment Fee
                                                  </strong>
                                                </td>
                                                <td className="text-right p-1">
                                                  {formatCurrency(
                                                    parseFloat(
                                                      -1 *
                                                        order.fulfillmentFee +
                                                        ''
                                                    )
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <strong>Platform Fee</strong>
                                                </td>
                                                <td className="text-right p-1">
                                                  {formatCurrency(
                                                    -1 *
                                                      parseFloat(
                                                        order.platformFee + ''
                                                      )
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <strong>Shipping Fee</strong>
                                                </td>
                                                <td className="text-right p-1">
                                                  {formatCurrency(
                                                    -1 *
                                                      parseFloat(
                                                        (order.shippingFee ||
                                                          0) + ''
                                                      )
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      }
                                      dismissOnMouseOut={true}
                                    >
                                      <div>
                                        <span>
                                          {formatCurrency(
                                            parseFloat(order.totalCharge + '')
                                          )}
                                        </span>{' '}
                                      </div>
                                    </Tooltip>
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-3 text-right text-sm sm:pr-6 text-green-700 font-medium">
                                    {formatCurrency(profit)}
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-3 text-left text-sm sm:pr-6 text-gray-500">
                                    <StatusBadge status={order.status} />
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-3 text-left text-xs sm:pr-6 text-gray-500">
                                    {trackingNumberMarkup(order)}
                                  </td>
                                  <td className="py-4 pl-3 text-xs sm:pr-6">
                                    {order.notes && (
                                      <Tooltip
                                        content={
                                          <div className="text-xs">
                                            {order.notes}
                                          </div>
                                        }
                                        dismissOnMouseOut={true}
                                      >
                                        <div className="hover:underline hover:cursor-pointer font-medium text-blue-500">
                                          View Notes
                                        </div>
                                      </Tooltip>
                                    )}
                                  </td>
                                  {authInfo.auth.admin && (
                                    <>
                                      <td className="text-xs text-green-700 font-medium sm:pr-6 whitespace-nowrap py-4 pl-3 text-left">
                                        {dkProfit}
                                      </td>
                                      <td className="text-xs sm:pr-6whitespace-nowrap py-4 pl-3 text-left">
                                        {order.shopDomain}
                                        <br />
                                        {order.shopEmail}
                                        <br />
                                        {order.SK}
                                      </td>
                                      <td className="font-medium whitespace-nowrap py-4 pl-3 text-left">
                                        {order.orderStatusUrl && (
                                          <>
                                            <a
                                              className="hover:underline text-xs sm:pr-6 text-blue-500"
                                              href={order.orderStatusUrl}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              View Status
                                            </a>
                                            <br />
                                          </>
                                        )}
                                        {order.adminQueryUrl && (
                                          <a
                                            className="hover:underline text-xs sm:pr-6 text-blue-500"
                                            href={order.adminQueryUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            View JSON
                                          </a>
                                        )}
                                        <br />
                                        <button
                                          onClick={() => {
                                            setSelectedOrderId(
                                              order.SK.split('#')[1]
                                            );
                                            setSelectedShopDomain(
                                              order.shopDomain
                                            );
                                            setModalActive(true);
                                          }}
                                          className="hover:underline text-xs sm:pr-6 text-blue-500"
                                        >
                                          Reprocess Order
                                        </button>
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                    {ordersInfo.items.length === 0 && <NoRecordsFound />}
                    <Pagination
                      entityInfo={ordersInfo}
                      handlePageSizeChange={handlePageSizeChange}
                      handleChangePage={handleChangePage}
                      location="footer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Page>

      <Modal
        open={modalActive}
        primaryAction={{
          content: 'Reprocess Order',
          loading: modalLoading,
          onAction: handleReprocess
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setModalActive(false);
            }
          }
        ]}
        onClose={() => setModalActive(false)}
        title={'Reprocess Order'}
      >
        <Modal.Section>
          <ModalStyles>
            <DescriptionList
              items={[
                {
                  term: 'Order ID',
                  description: selectedOrderId
                },
                {
                  term: 'Shop Domain',
                  description: selectedShopDomain
                }
              ]}
            />
            <br />
            <Checkbox
              label="Skip Email Notifications"
              checked={skipEmailNotifications}
              onChange={(value) => {
                setSkipEmailNotifications(value);
              }}
            />
            <br />
            <Checkbox
              label="Skip Store Owner Charge"
              checked={skipStoreOwnerCharge}
              onChange={(value) => {
                setSkipStoreOwnerCharge(value);
              }}
            />
            <br />
            <Checkbox
              label="Skip Fulfillment"
              checked={skipFulfillment}
              onChange={(value) => {
                setSkipFulfillment(value);
              }}
            />
          </ModalStyles>
        </Modal.Section>
      </Modal>
      <Modal
        open={manuallyFulfilledModalActive}
        primaryAction={{
          content: 'Mark as Manually Fulfilled',
          loading: manuallyFulfilledModalLoading,
          onAction: handleManualComplete
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setManuallyFulfilledModalActive(false);
            }
          }
        ]}
        onClose={() => setManuallyFulfilledModalActive(false)}
        title={'Mark as Complete'}
      >
        <Modal.Section>
          <ModalStyles>
            <DescriptionList
              items={[
                {
                  term: 'Order ID',
                  description: selectedOrderId
                },
                {
                  term: 'Shop Domain',
                  description: selectedShopDomain
                }
              ]}
            />
          </ModalStyles>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default Orders;
