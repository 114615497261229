import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

import PageSizeSelect from './PageSizeSelect';
import { PaginationProps } from '../types/Pagination';
import { TableState } from '../types/TableState';
import { classNames } from '../utils';
import { useState } from 'react';

export default function Pagination({
  entityInfo = {} as TableState,
  location = 'header',
  handleBlurSearch = () => {},
  handleChangePage = () => {},
  handleKeyUp = () => {},
  handlePageSizeChange = () => {}
}: PaginationProps) {
  const { pageSize, exclusiveStartKey, search, lastEvaluatedKey } = entityInfo;
  const [searchQuery, setSearchQuery] = useState(search);
  return (
    <div
      className={classNames(
        'bg-gray-50 px-4 py-3 flex items-center justify-between overflow-y-visible sm:px-border-gray-300',
        location === 'header' ? 'border-b' : 'border-t'
      )}>
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={(e) => {
            handleChangePage('prev');
          }}
          disabled={!exclusiveStartKey}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Previous
        </button>
        <button
          onClick={(e) => {
            handleChangePage('next');
          }}
          disabled={!lastEvaluatedKey}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex-1 text-left">
          {location === 'header' && (
            <>
              {' '}
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5  text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  className="block w-2/3 h-full pl-10 pr-3 py-2 border-gray-300 text-gray-900 placeholder-gray-300 focus:outline-none focus:placeholder-gray-300 focus:ring-1 sm:text-sm border rounded-md"
                  placeholder="Search..."
                  onBlur={(e) => handleBlurSearch(e.target.value)}
                  onKeyUp={(e) => handleKeyUp(e)}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  type="search"
                  name="search"
                />
              </div>
            </>
          )}
          {location === 'footer' && (
            <PageSizeSelect
              location={location}
              handlePageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
            />
          )}
        </div>
        <div className="flex-1 text-center">
          {location === 'footer' && (
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">{entityInfo.items.length}</span>{' '}
              result{entityInfo.items.length > 1 ? 's' : ''}
            </p>
          )}
        </div>
        <div className="flex-1 text-right">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination">
            <button
              onClick={(e) => {
                handleChangePage('prev');
              }}
              disabled={!exclusiveStartKey}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={(e) => {
                handleChangePage('next');
              }}
              disabled={!lastEvaluatedKey}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed">
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
