export enum OrderStatus {
  Initiated = 'Initiated',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
  Error = 'Error',
  FulfillmentError = 'Fulfillment Error',
  OutOfStock = 'Out of Stock',
  CardDeclined = 'Card Declined',
  Unknown = 'Unknown',
  Paid = 'Paid',
  Unpaid = 'Unpaid'
}
