import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';
import {
  useClientRouting,
  useRoutePropagation
} from '@shopify/app-bridge-react';

import Dashboard from './screens/Dashboard';
import { Frame } from '@shopify/polaris';
import Imports from './screens/Imports';
import Orders from './screens/Orders';
import Products from './screens/Products';
import React from 'react';
import Settings from './screens/Settings';

const Routes: React.FC = (props: RouteComponentProps): JSX.Element => {
  const { history, location } = props;
  useClientRouting(history);
  useRoutePropagation(location);

  return (
    <Frame>
      <Switch>
        <Route path="/products" component={Products} />
        <Route path="/orders" component={Orders} />
        <Route path="/imports" component={Imports} />
        <Route path="/settings" component={Settings} />
        <Route exact path="/" component={Dashboard} />
      </Switch>
    </Frame>
  );
};

export default withRouter(Routes);
