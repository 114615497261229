import { ShopifyConfig } from './types/Config';

const defaultConfig = {
  scope:
    'read_products,write_products,read_orders,write_orders,read_draft_orders,write_draft_orders,read_fulfillments,write_fulfillments,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders',
  apiVersion: '2023-04'
};

const shopify: ShopifyConfig[] = [
  {
    id: 'local',
    apiUrl: 'exct-api.ngrok.io',
    apiKey: '96c1f10e69ebf5c1394922c82c3d4d1e',
    ...defaultConfig
  },
  {
    id: 'development',
    apiUrl: 'api-dev.velvetship.app',
    apiKey: '0fc69936510e358d14b3d52246477ed1',
    ...defaultConfig
  },
  {
    id: 'production-local',
    apiUrl: 'exct-api.ngrok.io',
    apiKey: '2fe000fcde46f04f3178fd4f2c9a2aae',
    ...defaultConfig
  },
  {
    id: 'production',
    apiUrl: 'api.velvetship.app',
    apiKey: '2fe000fcde46f04f3178fd4f2c9a2aae',
    ...defaultConfig
  }
];

export const loadConfig = (id = 'production') => {
  const env =
    window.location.href.indexOf('client.velvetship.app') >= 0
      ? 'production'
      : window.location.href.indexOf('client-dev.velvetship.app') >= 0
      ? 'development'
      : 'local';
  const config = shopify.find((c) => c.id === env);
  if (!config) {
    throw new Error('Error loading invalid config');
  }
  console.log('loaded config', config);
  return {
    shopify: config
  };
};
