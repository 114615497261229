import { authState, toastState } from '../atoms';
import { formatCurrency, htmlDecode, request } from '../utils';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AuthInfo } from '../types/AuthInfo';
import { Modal } from '@shopify/polaris';
import StatusBadge from './StatusBadge';
import styled from 'styled-components';

const ProductInfoStyle = styled.div`
  p,
  > div {
    margin-bottom: 1rem;
  }
`;

const ProductDetailsModal = ({ dismiss, product }: any) => {
  const authInfo: AuthInfo = useRecoilValue(authState);
  const [, setLoading] = useState(true);
  const [productDetail, setProductDetail] = useState<any>(null);
  const [primaryImageSrc, setPrimaryImageSrc] = useState(product.Main_Image);
  const [, setToast] = useRecoilState(toastState);

  useEffect(() => {
    (async () => {
      try {
        console.log('getting product details...');
        const result = await request('POST', 'products', authInfo.auth, {
          productId: product.SK
        });
        if (!result) {
          setToast({
            active: true,
            message: 'Error getting product detail',
            type: 'error'
          });
          return;
        }
        console.log('got product detail', result);
        const tmpProductDetail = {
          ...result,
          images: [
            result.Main_Image,
            result.Image_2,
            result.Image_3,
            result.Image_4,
            result.Image_5
          ].filter((image) => !!image)
        };

        setProductDetail(tmpProductDetail);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [authInfo.auth, product, setToast]);

  const handleImageChange = useCallback((image) => {
    setPrimaryImageSrc(image);
  }, []);

  return (
    <Modal
      open={true}
      large
      onClose={() => dismiss(false)}
      secondaryActions={[
        {
          content: 'Close',
          onAction: () => {
            dismiss(false);
          }
        }
      ]}
      title={htmlDecode(product.Product_Name)}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }}>
          <div className="p-4">
            <div
              style={{
                backgroundImage: 'url(' + primaryImageSrc + ')',
                minHeight: '300px'
              }}
              className="w-full bg-no-repeat bg-contain bg-center"></div>
          </div>
          <div className="p-4">
            {productDetail &&
              productDetail.images.length > 1 &&
              productDetail.images.map((image: any, index: number) => {
                return (
                  <div
                    key={'product-imae-' + index}
                    onClick={() => {
                      handleImageChange(image);
                    }}
                    className="p-2 border hover:cursor-pointer bg-no-repeat rounded m-1 bg-contain bg-center border-gray-100 hover:border-gray-300 border-radius b-2 inline-block w-100"
                    style={{
                      backgroundImage: 'url(' + image + ')',
                      width: '124px',
                      height: '124px'
                    }}></div>
                );
              })}
          </div>
        </div>
        <div style={{ flex: 2, padding: '2rem' }}>
          <ProductInfoStyle>
            <strong>MSRP - Your Cost = Your Profit</strong>
            <p>
              {' '}
              <span>
                {formatCurrency(parseFloat(product.Suggested_Retail_Price))}
              </span>{' '}
              -{' '}
              <span>{formatCurrency(parseFloat(product.Wholesale_Price))}</span>{' '}
              ={' '}
              <span className="font-medium text-green-700">
                {formatCurrency(
                  parseFloat(product.Suggested_Retail_Price) -
                    parseFloat(product.Wholesale_Price)
                )}
              </span>
            </p>
            <strong>SKU</strong>
            <p>
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                {product.Product_Number}
              </span>
            </p>
            <strong>UPC</strong>
            <p>
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                {productDetail && productDetail.UPC}
              </span>
            </p>
            <strong>Stock Info</strong>
            <p>
              <StatusBadge status={product.Stock_Info} />
            </p>
            <strong>Category</strong>
            <p className="text-gray-500 italic">
              {htmlDecode(
                `${product.Category_1} ${
                  product.Category_2 && '> ' + product.Category_2
                } ${product.Category_3 && '> ' + product.Category_3}`
              )}
            </p>
            <strong>Description</strong>
            <p
              dangerouslySetInnerHTML={{
                __html: htmlDecode(product.Long_Description)
              }}></p>
            {productDetail && productDetail.Feature && (
              <>
                <strong>Feature</strong>
                <p className="text-gray-500 italic">
                  {productDetail.Feature.replace(',', ', ')}
                </p>
              </>
            )}
            {productDetail && productDetail.Function && (
              <>
                <strong>Function</strong>
                <p>{productDetail.Function}</p>
              </>
            )}
            {productDetail && productDetail.Length && (
              <>
                <strong>Length</strong>
                <p>{productDetail.Length + 'in'}</p>
              </>
            )}
            {productDetail && productDetail.Width && (
              <>
                <strong>Width</strong>
                <p>{productDetail.Width + 'in'}</p>
              </>
            )}
            {productDetail && productDetail.Weight && (
              <>
                <strong>Weight</strong>
                <p>{productDetail.Weight + 'lbs'}</p>
              </>
            )}
          </ProductInfoStyle>
        </div>
      </div>
    </Modal>
  );
};

export default ProductDetailsModal;
