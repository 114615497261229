import {
  Button,
  Card,
  Icon,
  Modal,
  Stack,
  TextContainer,
  TextStyle
} from '@shopify/polaris';
import { CircleAlertMajor, CircleTickMajor } from '@shopify/polaris-icons';
import { authState, toastState } from '../../atoms';
import { useCallback, useEffect, useState } from 'react';

import { Toast as IToast } from '../../types/Toast';
import { request } from '../../utils';
import { useRecoilState } from 'recoil';

const STEP_NAME = 'review-terms';

const ReviewTermsStatus = (): JSX.Element => {
  const [authInfo, setAuthInfo] = useRecoilState(authState);
  const [complete, setComplete] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [agreeLoading, setAgreeLoading] = useState(false);
  const handleClose = useCallback(() => setModalActive(false), []);
  const handleOpen = useCallback(() => setModalActive(true), []);
  const [, setToast]: [IToast, any] = useRecoilState(toastState);

  const handleAgree = useCallback(async () => {
    setAgreeLoading(true);

    // update welcome tour status
    const result = await request('POST', 'shop', authInfo.auth, {
      operation: STEP_NAME
    });
    if (!result) {
      setToast({
        active: true,
        message: 'An Error Occured',
        type: 'error'
      });
      return;
    }

    setAuthInfo({
      ...authInfo,
      auth: {
        ...authInfo.auth,
        welcomeTourStatus: JSON.stringify([STEP_NAME])
      }
    });
    setAgreeLoading(false);
    setModalActive(false);
    setComplete(true);
  }, [authInfo, setAuthInfo, setToast]);

  // set completion
  useEffect(() => {
    setComplete(
      authInfo.auth.welcomeTourStatus
        ? authInfo.auth.welcomeTourStatus.indexOf(STEP_NAME) >= 0
        : false
    );
  }, [authInfo, setAuthInfo]);

  return (
    <>
      <Card.Subsection>
        <Stack wrap={false} alignment="center">
          <Stack.Item>
            {complete ? (
              <Icon source={CircleTickMajor} color="primary" />
            ) : (
              <Icon source={CircleAlertMajor} color="warning" />
            )}
          </Stack.Item>
          <Stack.Item>
            <div className="step-title">Review Terms of Use</div>
            <div>
              {complete ? (
                <div className="step-subtitle">
                  <div className="step-message">
                    <TextStyle variation="positive">
                      Great! You’ve accepted the terms.
                    </TextStyle>
                  </div>
                  <div className="step-button">
                    <Button size="slim" onClick={handleOpen}>
                      Review Terms
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="step-subtitle">
                  <div className="step-message">
                    <TextStyle variation="subdued">
                      Please review and accept the terms of use.
                    </TextStyle>
                  </div>
                  <div className="step-button">
                    <Button primary size="slim" onClick={handleOpen}>
                      Accept Terms
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Stack.Item>
        </Stack>
      </Card.Subsection>

      <Modal
        open={modalActive}
        primaryAction={{
          content: 'I Agree to These Terms',
          loading: agreeLoading,
          disabled: complete,
          onAction: handleAgree
        }}
        secondaryActions={[
          {
            content: 'Close',
            onAction: handleClose
          }
        ]}
        onClose={handleClose}
        title={'Accept Terms of Use'}>
        <Modal.Section>
          <TextContainer>
            <p style={{ textAlign: 'center' }}>
              In order to sell products through this app, you must acknowledge
              that you have read and understood the official Terms of Use.
            </p>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={() => {
                  window.open(authInfo.app.termsUrl, '_blank');
                }}>
                View Terms of Use
              </Button>
            </div>
            {complete && (
              <TextStyle variation="positive">
                <br />
                <div style={{ textAlign: 'center' }}>
                  You have already accepted these terms.
                </div>
              </TextStyle>
            )}
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default ReviewTermsStatus;
