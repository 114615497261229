import { Button, Card } from '@shopify/polaris';

import { authState } from '../atoms';
import { useRecoilValue } from 'recoil';

const DebugInformation = (): JSX.Element => {
  const authInfo = useRecoilValue(authState);
  return (
    <Card>
      <Card.Section title="Shop ID">
        <p>{authInfo.auth.SK}</p>
      </Card.Section>
      <Card.Section title="Shop Domain">
        <p>{authInfo.auth.shopDomain}</p>
      </Card.Section>
      <Card.Section title="Shop Owner Email">
        <p>{authInfo.auth.shopEmail}</p>
      </Card.Section>
      <Card.Section title="Shop Plan">
        <p>{authInfo.auth.shopPlan}</p>
      </Card.Section>
      <Card.Section title="Shop Timezone">
        <p>{authInfo.auth.shopTimezone}</p>
      </Card.Section>
      <Card.Section title="Clear Cache">
        <p>
          Use this button to clear your local cache, which can sometimes help
          solve issues by resetting app settings. Click the button below and
          then refresh your browser.
        </p>
        <br />
        <Button
          onClick={() => {
            window.localStorage.clear();
          }}>
          Clear Cache
        </Button>
      </Card.Section>
    </Card>
  );
};

export default DebugInformation;
