import 'elt-react-credit-cards/es/styles-compiled.css';

import {
  Banner,
  Button,
  Card,
  Form,
  Select,
  Stack,
  TextField
} from '@shopify/polaris';
import { authState, toastState } from '../atoms';
import { useCallback, useState } from 'react';

import Cards from 'elt-react-credit-cards';
import { Toast } from '../types/Toast';
import amex from '../assets/img/card-icons/amex.svg';
import discover from '../assets/img/card-icons/discover.svg';
import generic from '../assets/img/card-icons/generic.svg';
import mastercard from '../assets/img/card-icons/mastercard.svg';
import { request } from '../utils';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import visa from '../assets/img/card-icons/visa.svg';

export interface ISaveCardRequest {
  shopId: string;
  name: string;
  cardNumber: string;
  cardExpires: string;
}

const STEP_NAME = 'save-payment';

const translateCardType = (type) => {
  switch (type) {
    case 'Visa':
      return visa;
    case 'Amex':
      return amex;
    case 'Discover':
      return discover;
    case 'MasterCard':
      return mastercard;
    default:
      return generic;
  }
};

const stateOptions = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

const CreditCardFieldStyle = styled.div`
  .rccs__card {
    width: 250px;
  }
`;
const SelectStyles = styled.div`
  width: 100%;
  option {
    padding: 20px;
  }
`;

const CardStyle = styled.div`
  .Polaris-TextField__Backdrop {
    background-size: 38px;
    background-repeat: no-repeat;
    background-position: right 6px center;
    background-image: url(${(props) => {
      return props.className ? translateCardType(props.className) : '';
    }});
  }
`;

const PaymentMethodEditor = (): JSX.Element => {
  const [authInfo, setAuthInfo] = useRecoilState(authState);
  const [, setToast]: [Toast, any] = useRecoilState(toastState);

  // default to showing the edit mode if the user does not yet have a payment method
  // const [mode, setMode] = useState(authInfo.auth.ccName ? 'view' : 'edit');
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(authInfo.auth.ccName);
  const [cardNumber, setCardNumber] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cvc, setCvc] = useState('');
  const [address, setAddress] = useState(authInfo.auth.ccAddress);
  const [address2, setAddress2] = useState(authInfo.auth.ccAddress2);
  const [city, setCity] = useState(authInfo.auth.ccCity);
  const [state, setState] = useState(authInfo.auth.ccState);
  const [zipCode, setZipCode] = useState(authInfo.auth.ccZipCode);

  const [focused, setFocused] = useState('');
  const [loading, setLoading] = useState(false);

  const saveDisabled = () => {
    return (
      !name ||
      !cardNumber ||
      !expiration ||
      !cvc ||
      !address ||
      !city ||
      !state ||
      !zipCode
    );
  };

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setName('');
    setExpiration('');
    setCardNumber('');
    setCvc('');
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const result = await request('POST', 'save_payment', authInfo.auth, {
        name,
        cardNumber: cardNumber,
        cardExpires: expiration,
        cvc,
        address,
        address2,
        city,
        state,
        zipCode
      });
      if (!result) {
        setToast({
          active: true,
          type: 'error',
          message: 'Error saving payment method'
        });
        return;
      }
      console.log('result', result);
      setToast({
        active: true,
        type: 'success',
        message: 'Payment method saved!'
      });
      const welcomeTourStatus = JSON.parse(
        authInfo.auth.welcomeTourStatus || '{}'
      );
      welcomeTourStatus.push(STEP_NAME);

      // map the resulting fields
      const updatedCCInfo = {
        ccCardType: result.data.xCardType,
        ccDate: result.data.xDate,
        ccError: result.data.xError,
        ccErrorCode: result.data.xErrorCode,
        ccExp: result.data.xExp,
        ccMaskedCardNumber: result.data.xMaskedCardNumber,
        ccName: result.data.xName,
        ccRefNum: result.data.xRefNum,
        ccResult: result.data.xResult,
        ccStatus: result.data.xStatus,
        ccToken: result.data.xToken,

        // new fields 12/17/22
        ccAddress: result.data.xAddress,
        ccAddress2: result.data.xAddress2,
        ccCity: result.data.xCity,
        ccState: result.data.xState,
        ccZipCode: result.data.xZipCode,
        ccIP: result.data.xIP,
        ccCVC: result.data.xCVC
      };

      // update saved local auth
      setAuthInfo({
        ...authInfo,
        auth: {
          ...authInfo.auth,
          ...updatedCCInfo,
          welcomeTourStatus: JSON.stringify(welcomeTourStatus)
        }
      });
      setName(result.data.xName);
      setExpiration(result.data.xExp);
      setCardNumber(result.data.xMaskedCardNumber);
      setIsEditing(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [
    address,
    address2,
    authInfo,
    cardNumber,
    city,
    cvc,
    expiration,
    name,
    setAuthInfo,
    setToast,
    state,
    zipCode
  ]);

  const ccFormat = (value) => {
    if (!value) {
      return '';
    }
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts: any = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  const formattedExpiration =
    authInfo && authInfo.auth && authInfo.auth.ccExp
      ? authInfo.auth.ccExp.substring(0, 2) +
        '/' +
        authInfo.auth.ccExp.substring(2, 4)
      : '';

  return (
    <Card>
      <Form onSubmit={handleSubmit}>
        <Card.Section title="Credit Card Information">
          <>
            {authInfo.auth && !authInfo.auth.ccName && (
              <>
                <Banner title="" status="warning">
                  <p>
                    You must add credit card information before you can import
                    products.
                  </p>
                </Banner>
                <br />
              </>
            )}
            {isEditing && (
              <CardStyle>
                <Cards
                  cvc={cvc}
                  expiry={expiration}
                  focused={focused}
                  name={name}
                  number={cardNumber}
                />
              </CardStyle>
            )}
            <TextField
              value={isEditing ? name : authInfo.auth.ccName}
              label="Name on Card"
              error={!name && isEditing ? 'Required' : ''}
              autoComplete="cc-name"
              selectTextOnFocus
              placeholder="Enter Name..."
              type="text"
              onFocus={() => setFocused('name')}
              onChange={(value) => {
                setName(value);
              }}
              disabled={!isEditing}
            />
            <CardStyle className={isEditing ? '' : authInfo.auth.ccCardType}>
              <TextField
                value={
                  isEditing ? cardNumber : authInfo.auth.ccMaskedCardNumber
                }
                error={!cardNumber && isEditing ? 'Required' : ''}
                label="Card Number"
                autoComplete="cc-number"
                placeholder="XXXX XXXX XXXX XXXX"
                type="text"
                onFocus={() => setFocused('number')}
                onChange={(value) => {
                  setCardNumber(ccFormat(value));
                }}
                selectTextOnFocus
                disabled={!isEditing}
              />
            </CardStyle>
            <Stack>
              <TextField
                value={isEditing ? expiration : formattedExpiration}
                error={!expiration && isEditing ? 'Required' : ''}
                label="Expires"
                autoComplete="cc-expires"
                placeholder="MMYY"
                type="text"
                onFocus={() => setFocused('expiry')}
                selectTextOnFocus
                onChange={(value) => {
                  setExpiration(value);
                }}
                disabled={!isEditing}
              />
              <TextField
                value={cvc}
                error={!cvc && isEditing ? 'Required' : ''}
                autoComplete="cc-cvc"
                onFocus={() => setFocused('cvc')}
                selectTextOnFocus
                onChange={(value) => {
                  setCvc(value);
                }}
                label="CVC"
                placeholder="XXX"
                type="text"
                disabled={!isEditing}
              />
            </Stack>
          </>
        </Card.Section>
        <Card.Section title="Card Billing Address">
          <TextField
            value={isEditing ? address : authInfo.auth.ccAddress}
            label="Street Address"
            autoComplete="cc-address1"
            placeholder="Enter Street Address..."
            selectTextOnFocus
            type="text"
            error={!address && isEditing ? 'Required' : ''}
            onChange={(value) => {
              setAddress(value);
            }}
            disabled={!isEditing}
          />
          <TextField
            value={isEditing ? address2 : authInfo.auth.ccAddress2}
            label="Street Address 2"
            selectTextOnFocus
            autoComplete="cc-address2"
            placeholder="Apartment, suite, etc. (optional)"
            type="text"
            onChange={(value) => {
              setAddress2(value);
            }}
            disabled={!isEditing}
          />
          <Stack distribution="fillEvenly">
            <TextField
              value={isEditing ? city : authInfo.auth.ccCity}
              error={!city && isEditing ? 'Required' : ''}
              label="City"
              autoComplete="cc-city"
              placeholder="Enter City..."
              type="text"
              selectTextOnFocus
              onChange={(value) => {
                setCity(value);
              }}
              disabled={!isEditing}
            />
            <SelectStyles>
              <Select
                value={isEditing ? state : authInfo.auth.ccState}
                label="State"
                placeholder="Select State"
                onChange={(value) => {
                  setState(value);
                }}
                error={!state && isEditing ? 'Required' : ''}
                options={stateOptions}
                disabled={!isEditing}
              />
            </SelectStyles>
            <TextField
              value={isEditing ? zipCode : authInfo.auth.ccZipCode}
              label="Zip Code"
              error={!zipCode && isEditing ? 'Required' : ''}
              autoComplete="cc-zip"
              placeholder="Enter Zip Code..."
              selectTextOnFocus
              type="text"
              onChange={(value) => {
                setZipCode(value);
              }}
              disabled={!isEditing}
            />
          </Stack>
        </Card.Section>
        <Card.Section>
          <Stack>
            {isEditing ? (
              <>
                <Button
                  primary
                  submit
                  loading={loading}
                  disabled={saveDisabled() || loading}>
                  Save
                </Button>{' '}
                <Button submit onClick={handleCancel} disabled={loading}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button primary onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            )}
          </Stack>
        </Card.Section>
      </Form>
    </Card>
  );
};

export default PaymentMethodEditor;
