import { Menu, Transition } from '@headlessui/react';

import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';

export default function PageSizeSelect({
  location,
  pageSize,
  handlePageSizeChange
}) {
  return (
    <Menu as="div" className="relative inline-block text-left mr-2 z-10">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <span className="mr-1">Page Size:</span>
          <span className="font-medium">{pageSize}</span>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="origin-bottom absolute bottom-12 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
          {[10, 25, 50, 100].map((pageSizeOption) => {
            return (
              <Menu.Item key={location + '-pagesizeoption-' + pageSizeOption}>
                <button
                  onClick={() => handlePageSizeChange(pageSizeOption)}
                  className="block px-4 py-2 text-sm cursor-pointer w-full hover:bg-gray-50">
                  {pageSizeOption}
                </button>
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
