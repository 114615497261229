import { ShopifyHeaders } from './types/ShopifyHeaders';
import axios from 'axios';
import { loadConfig } from './config';

const config = loadConfig();

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const elapsedTime = (startTime: any) => {
  return convertSecondsToHHMMSS(elapsedSeconds);
};

const elapsedSeconds = (startTime: any) => {
  return (new Date().getTime() - startTime) / 1000;
};

const convertSecondsToHHMMSS = function (input: any) {
  const secNum = parseInt(input, 10); // don't forget the second param
  let hours: any = Math.floor(secNum / 3600);
  let minutes: any = Math.floor((secNum - hours * 3600) / 60);
  let seconds: any = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
};

const buildResponseHeaders = (additionalHeaders?: any) => {
  return {
    ...additionalHeaders,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Methods': '*'
  };
};

const formatCurrency = (input: number) => {
  return input.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

const htmlDecode = (input: any): string => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent || '';
};

const decodeEntities = (encodedString) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return decodeURI(textArea.value);
};

const decodeHTMLEntities = (text: any): string => {
  if (!text) {
    return '';
  }

  const entities = [
    // eslint-disable-next-line quotes
    ['apos', "'"],
    // eslint-disable-next-line quotes
    ['#x27', "'"],
    // eslint-disable-next-line quotes
    ['#x39', "'"],
    // eslint-disable-next-line quotes
    ['#039', "'"],
    // eslint-disable-next-line quotes
    ['#39', "'"],
    ['amp', '&'],
    ['#x2F', '/'],
    ['#47', '/'],
    ['lt', '<'],
    ['gt', '>'],
    ['nbsp', ' '],
    // ['quot', '"'],
    ['quot', 'in']
  ];

  for (let i = 0, max = entities.length; i < max; ++i)
    text = text.replace(
      new RegExp('&' + entities[i][0] + ';', 'g'),
      entities[i][1]
    );

  return text;
};

interface ApiError {
  code: number;
  error: string;
  response: {
    data: any;
  };
}

const isApiError = (x: any): x is ApiError => {
  return typeof x.code === 'number';
};

const parseQuery = (queryString) => {
  const query = {};
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

// make axios request in consistent way and get the data back
const request = async (
  method: 'GET' | 'POST',
  relativeUrl: string,
  auth: any,
  data?: any
) => {
  try {
    // console.log('request', method, relativeUrl, data || '');
    const version = 'v1';
    const url = `https://${config.shopify.apiUrl}/${version}/${relativeUrl}`;
    const { SK: shopId, shopDomain, appId, accessToken } = auth;
    const result = await axios({
      method: method,
      url: url,
      headers: {
        [ShopifyHeaders.shopId]: shopId,
        [ShopifyHeaders.domain]: shopDomain,
        [ShopifyHeaders.appId]: appId,
        [ShopifyHeaders.accessToken]: accessToken
      },
      data: data
    });
    // console.log('result', result);
    if (!result.data) {
      throw new Error('No data returned from API');
    } else if (result.status !== 200) {
      throw new Error('Invalid status returned from API');
    }
    return result.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const getTrackingUrl = (trackingNumber: string): string => {
  // if (trackingNumber.length === 26 || trackingNumber.length === 22) {
  //   // usps
  //   return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`;
  // } else if (trackingNumber.length === 18) {
  //   return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}&loc=en_us&requester=ST/trackdetails`;
  // } else if (trackingNumber.length === 12) {
  //   return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`;
  // }

  if (trackingNumber.length === 18 && trackingNumber.startsWith('8027')) {
    return `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}/trackdetails`;
  }

  return `https://t.17track.net/en#nums=${trackingNumber}`;
};

export {
  htmlDecode,
  decodeEntities,
  decodeHTMLEntities,
  delay,
  getRandomInt,
  elapsedTime,
  elapsedSeconds,
  convertSecondsToHHMMSS,
  buildResponseHeaders,
  formatCurrency,
  isApiError,
  parseQuery,
  request,
  classNames,
  getTrackingUrl
};
