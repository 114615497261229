import { OrderStatus } from '../types/OrderStatus';
import { StockStatus } from '../types/StockStatus';

// todo - add tooltips
const StatusBadge = ({ status = 'Unknown' }) => {
  switch (status) {
    case OrderStatus.InProgress:
    case OrderStatus.Paid:
    case OrderStatus.Unpaid:
    case OrderStatus.Initiated:
    case StockStatus.OutOfStock:
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
          {status}
        </span>
      );
    case OrderStatus.Complete:
    case StockStatus.InStock:
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
          {status}
        </span>
      );
    case OrderStatus.Cancelled:
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
          {status}
        </span>
      );
    case OrderStatus.Error:
    case OrderStatus.FulfillmentError:
    case OrderStatus.OutOfStock:
    case OrderStatus.CardDeclined:
    case StockStatus.Deleted:
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
          {status}
        </span>
      );
    case OrderStatus.Unknown:
    default:
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
          {status}
        </span>
      );
  }
};

export default StatusBadge;
