import {
  Card,
  Heading,
  Layout,
  Page,
  Select,
  TextContainer
} from '@shopify/polaris';

import DebugInformation from '../components/DebugInformation';
import DropKickTitleBar from '../components/DropKickTitleBar';
import PaymentMethodEditor from '../components/PaymentMethodEditor';
import React from 'react';

const languageOptions = [{ label: 'English', value: 'english' }];
const countryOptions = [{ label: 'United States', value: 'us' }];

const Settings: React.FC = (): JSX.Element => {
  return (
    <>
      <DropKickTitleBar title="Settings" />
      <Page title="Settings" titleHidden={true}>
        <br />
        <br />
        <Layout>
          <Layout.Section secondary>
            <TextContainer>
              <Heading>Payment Info</Heading>
              <p>
                Your credit card information is stored securely by our payment
                provider and is used to pay for fulfillment of products when an
                order is placed.
              </p>
            </TextContainer>
          </Layout.Section>
          <Layout.Section>
            <PaymentMethodEditor />
          </Layout.Section>
        </Layout>
        <br />
        <br />
        <Layout>
          <Layout.Section secondary>
            <TextContainer>
              <Heading>Your Store's Location</Heading>
              <p>
                If you want your shop location to be in another region, you have
                to create another shop and select that region when installing
                the app.
              </p>
            </TextContainer>
          </Layout.Section>
          <Layout.Section>
            <Card title="Shop Location" sectioned>
              <Select
                label="Choose the location of your store."
                options={countryOptions}
                onChange={() => {}}
                value="us"
              />
            </Card>
          </Layout.Section>
        </Layout>
        <br />
        <br />
        <Layout>
          <Layout.Section secondary>
            <TextContainer>
              <Heading>Language</Heading>
              <p>
                Choose a language for your products. If you select default
                product descriptions and size table, they will be published in
                this language.
              </p>
            </TextContainer>
          </Layout.Section>
          <Layout.Section>
            <Card title="Store Language" sectioned>
              <Select
                label="Choose the language for your store, your customers will see this."
                options={languageOptions}
                onChange={() => {}}
                value="english"
              />
            </Card>
          </Layout.Section>
        </Layout>
        <br />
        <br />
        <Layout>
          <Layout.Section secondary>
            <TextContainer>
              <Heading>Debug Information</Heading>
              <p>
                This section contains debugging information used by the app
                developers to ensure a consistent user experience.
              </p>
            </TextContainer>
          </Layout.Section>
          <Layout.Section>
            <DebugInformation />
          </Layout.Section>
        </Layout>
        <br />
        <br />
      </Page>
    </>
  );
};

export default Settings;
