import '@shopify/polaris/build/esm/styles.css';
import './index.css';

import App from './App';
import { RecoilRoot } from 'recoil';
import { render } from 'react-dom';

render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById('root')
);
