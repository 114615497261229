import {
  AppProvider,
  Card,
  Frame,
  Layout,
  SkeletonBodyText,
  SkeletonPage,
  Spinner,
  TextContainer,
  Toast
} from '@shopify/polaris';
import { authState, toastState } from './atoms';
import { parseQuery, request } from './utils';
import styled, { ThemeProvider } from 'styled-components';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import { AuthInfo } from './types/AuthInfo';
import { BrowserRouter } from 'react-router-dom';
import { Toast as IToast } from './types/Toast';
import Routes from './Routes';
import { loadConfig } from './config';
import translations from '@shopify/polaris/locales/en.json';

const config = loadConfig();

export const theme = {
  colors: {
    green: '#007b5c'
  }
};

// redirect to the auth endpoint
const redirectOauthAuthorize = (queryStringShop) => {
  const nonce: string = Math.random().toString(36).slice(-10);
  const redirectUri = `https://${config.shopify.apiUrl}/v1/auth_callback`;
  const url = `https://${queryStringShop}/admin/oauth/authorize?client_id=${config.shopify.apiKey}&scope=${config.shopify.scope}&redirect_uri=${redirectUri}&state=${nonce}`;
  console.log('redirecting to: ', url);
  window.location.href = url;
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const setAuth = useSetRecoilState(authState);
  const authInfo: AuthInfo = useRecoilValue(authState);
  const parsedURL: any = parseQuery(window.location.search);
  const [toast, setToast]: [IToast, any] = useRecoilState(toastState);
  const {
    shopId: queryStringShopId,
    shop: queryStringShop,
    shopUrl: queryStringShopUrl,
    accessToken: queryStringAccessToken,
    host: queryStringHost,
    appId: queryStringAppId
  } = parsedURL;
  const appBridgeConfig = {
    apiKey: config.shopify.apiKey,
    host: queryStringHost,
    forceRedirect: true
  };

  const LoadingStyle = styled.div`
    padding: 2rem;
    text-align: center;
  `;

  // handle first app load, get shop info from db
  useEffect(() => {
    (async () => {
      if (queryStringAccessToken && !authInfo.auth.shopDomain) {
        console.log('Got access token, getting auth info from db...');
        const newAuthInfo: AuthInfo = await request('GET', 'auth', {
          shopId: queryStringShopId,
          shopDomain: queryStringShopUrl,
          appId: queryStringAppId,
          accessToken: queryStringAccessToken
        });
        if (!newAuthInfo || !newAuthInfo.auth || !newAuthInfo.auth.shopDomain) {
          setToast({
            active: true,
            message: 'Error getting user info',
            type: 'error'
          });
          return;
        }
        setAuth(newAuthInfo);
      }
      setLoading(false);
    })();
  }, [
    setAuth,
    authInfo,
    queryStringShopId,
    queryStringAppId,
    queryStringAccessToken,
    queryStringShopUrl,
    setToast
  ]);

  const loadingComponent = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <LoadingStyle>
                <Spinner size="large" />
              </LoadingStyle>
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  // handle first app install
  if (window.top === window.self && queryStringShop !== undefined) {
    console.log('handle first app install...');
    window.localStorage.clear();
    redirectOauthAuthorize(queryStringShop);
    return <></>;
  }

  // handle cleared cache
  if (!queryStringAccessToken && !authInfo.auth.shopDomain) {
    console.log('handle cleared cache...');
    window.localStorage.clear();
    redirectOauthAuthorize(queryStringShop);
    return <></>;
  }

  // handle toast
  const toastMarkup = toast.active ? (
    <Toast
      content={toast.message}
      error={toast.type === 'error'}
      onDismiss={() => {
        setToast({
          ...toast,
          active: false
        });
      }}
    />
  ) : null;

  console.log('appBridgeConfig', appBridgeConfig);

  return (
    <BrowserRouter>
      <AppProvider i18n={translations}>
        <AppBridgeProvider config={appBridgeConfig}>
          <ThemeProvider theme={theme}>
            <Frame>
              {loading ? loadingComponent : <Routes />}
              {toastMarkup}
            </Frame>
          </ThemeProvider>
        </AppBridgeProvider>
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;
