import { authState, toastState } from '../atoms';
import { useCallback, useEffect, useState } from 'react';

import { AuthInfo } from '../types/AuthInfo';
import { Modal } from '@shopify/polaris';
import { request } from '../utils';
import { useRecoilState } from 'recoil';

const STEP_NAME = 'import-product';

const ProductImportModal = ({ dismiss, importConfig, pageSize }: any) => {
  const [authInfo, setAuthInfo]: [AuthInfo, any] = useRecoilState(authState);
  const [loading, setLoading] = useState(true);
  const [countInfo, setCountInfo]: [any, any] = useState({});
  const [, setToast] = useRecoilState(toastState);

  const estimatedDuration = (productCount: number) => {
    productCount += 100; // buffer for initial time to start import
    if (productCount < 200) {
      return '<1 minute';
    }
    const ratePerSecond = 1;
    let totalSeconds = productCount / ratePerSecond;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    // count to get total number of products
    const countProductImport = async () => {
      console.log('Counting product import...', importConfig);
      setLoading(true);
      const result: any = await request('POST', 'products', authInfo.auth, {
        ...importConfig,
        count: true
      });
      if (!result) {
        setToast({
          active: true,
          message: 'Error getting product count',
          type: 'error'
        });
        setLoading(false);
        return;
      }
      console.log('got countInfo', result);

      result.hours = Math.floor(result.seconds / 3600);
      setCountInfo(result);
      setLoading(false);
    };
    countProductImport();
  }, [authInfo.auth, importConfig, setToast]);

  // handle when the user decides to actually do an import
  const handleImportProducts = useCallback(async () => {
    try {
      setLoading(true);
      console.log('importing products', importConfig);
      const result = await request('POST', 'imports_create', authInfo.auth, {
        ...importConfig,
        total: countInfo.count
      });
      if (!result) {
        setToast({
          active: true,
          message: 'Error importing products',
          type: 'error'
        });
        return;
      }

      // update welcome tour status
      if (
        authInfo.auth.welcomeTourStatus &&
        authInfo.auth.welcomeTourStatus?.indexOf(STEP_NAME) === -1
      ) {
        const welcomeTourStatus = JSON.parse(
          authInfo.auth.welcomeTourStatus || '[]'
        );
        welcomeTourStatus.push(STEP_NAME);
        setAuthInfo({
          ...authInfo,
          auth: {
            ...authInfo.auth,
            welcomeTourStatus: JSON.stringify(welcomeTourStatus)
          }
        });
      }
      setToast({
        active: true,
        message: 'Import Started!',
        type: 'success'
      });
      dismiss(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [importConfig, countInfo, authInfo, setAuthInfo, dismiss, setToast]);

  return (
    <Modal
      open={true}
      onClose={() => {
        dismiss(false);
      }}
      primaryAction={{
        content: 'Start Import',
        loading: loading,
        onAction: handleImportProducts
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            dismiss(false);
          }
        }
      ]}
      title={'Start Product Import'}>
      <Modal.Section>
        <div className="text-center">
          {loading ? (
            <>
              <div className="mt-2 mb-2 text-sm font-medium text-gray-700">
                Counting Products{' '}
              </div>
            </>
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                />
              </svg>
              <div className="mt-2 mb-2 text-sm font-medium text-gray-700">
                Ready to Import {countInfo.count.toLocaleString()} Products{' '}
              </div>
              <div className="mt-2 mb-2 text-sm font-medium text-green-700">
                Estimated Duration: {estimatedDuration(countInfo.count)}
              </div>
            </>
          )}

          <p className="mt-1 text-sm text-gray-500 mb-2">
            Are you sure you want to import the selected products into your
            store? The import job will not import duplicate products.
          </p>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default ProductImportModal;
