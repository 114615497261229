import { Button, Card, Icon, Stack, TextStyle } from '@shopify/polaris';
import { CircleAlertMajor, CircleTickMajor } from '@shopify/polaris-icons';
import React, { useEffect, useState } from 'react';

import { authState } from '../../atoms';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const STEP_NAME = 'save-payment';

const AddPaymentInfoStatus = (): JSX.Element => {
  const [complete, setComplete] = useState(false);
  const authInfo = useRecoilValue(authState);
  const history = useHistory();

  const buttonDisabled = authInfo.auth.welcomeTourStatus
    ? authInfo.auth.welcomeTourStatus?.indexOf('review-terms') < 0
    : false;

  // set completion
  useEffect(() => {
    if (authInfo.auth.welcomeTourStatus) {
      setComplete(authInfo.auth.welcomeTourStatus?.indexOf(STEP_NAME) >= 0);
    }
  }, [authInfo.auth.welcomeTourStatus, complete]);

  return (
    <>
      <Card.Subsection>
        <Stack wrap={false} alignment="center">
          <Stack.Item>
            {complete ? (
              <Icon source={CircleTickMajor} color="primary" />
            ) : (
              <Icon source={CircleAlertMajor} color="warning" />
            )}
          </Stack.Item>
          <Stack.Item>
            <div className="step-title">Add Payment Information</div>
            <div>
              {complete ? (
                <div className="step-subtitle">
                  <div className="step-message">
                    <TextStyle variation="positive">
                      Orders will now automatically be fulfilled
                    </TextStyle>
                  </div>
                  <div className="step-button">
                    <Button
                      size="slim"
                      disabled={buttonDisabled}
                      onClick={() => history.push('/settings')}>
                      Edit Payment Info
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="step-subtitle">
                  <div className="step-message">
                    <TextStyle variation="subdued">
                      Enter credit card information to pay fulfillment charges
                    </TextStyle>
                  </div>
                  <div className="step-button">
                    <Button
                      size="slim"
                      disabled={buttonDisabled}
                      onClick={() => history.push('/settings')}>
                      Add Payment Info
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Stack.Item>
        </Stack>
      </Card.Subsection>
    </>
  );
};

export default AddPaymentInfoStatus;
