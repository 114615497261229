import { AuthInfo } from '../types/AuthInfo';
import React from 'react';
import { TitleBar } from '@shopify/app-bridge-react';
import { authState } from '../atoms';
import { useRecoilValue } from 'recoil';

type TitleBarProps = {
  title: string;
};

const DropKickTitleBar: React.FC<TitleBarProps> = ({ title }): JSX.Element => {
  const authInfo: AuthInfo = useRecoilValue(authState);

  const buttonsDisabled =
    authInfo.auth && authInfo.auth.welcomeTourStatus
      ? authInfo.auth.welcomeTourStatus.indexOf('review-terms') < 0
      : false;

  const secondaryActions = [
    {
      content: 'Dashboard',
      url: '/'
    },
    {
      content: 'Products',
      url: '/products',
      disabled: buttonsDisabled
    },
    {
      content: 'Imports',
      url: '/imports',
      disabled: buttonsDisabled
    },
    {
      content: 'Orders',
      url: '/orders',
      disabled: buttonsDisabled
    },
    {
      content: 'Settings',
      url: '/settings',
      disabled: buttonsDisabled
    }
  ];
  const actionGroups = [
    {
      title: 'Help',
      actions: [
        {
          content: 'About',
          url: authInfo.app.websiteUrl,
          external: true
        },
        {
          content: 'FAQs',
          url: authInfo.app.faqUrl,
          external: true
        },
        {
          content: 'Submit a Ticket',
          url: 'mailto:' + authInfo.app.adminEmail,
          external: true
        }
      ]
    }
  ];

  return (
    <TitleBar
      title={title || 'Drop Kick'}
      secondaryActions={secondaryActions}
      actionGroups={actionGroups}
    />
  );
};

export default DropKickTitleBar;
