import { Loading } from '@shopify/polaris';
import { classNames } from '../utils';

const COLS = 6;
const ROWS = 5;

const LoadingSkeletonTable = () => {
  return (
    <>
      <Loading />
      <div className="w-full shadow border border-gray-200 rounded-lg overflow-hidden">
        <div className="border-b border-gray-300 p-4 w-full mx-auto">
          <div className="animate-pulse flex space-x-4">
            {new Array(COLS).fill(0).map((item, i) => {
              return (
                <div key={'header-el-' + i} className="flex-1 space-y-6 py-2">
                  <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                </div>
              );
            })}
          </div>
        </div>
        {new Array(ROWS).fill(0).map((item, i) => {
          return (
            <div
              key={'loading-' + i}
              className={classNames(
                i % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                'p-4 w-full mx-auto'
              )}>
              <div className="animate-pulse flex space-x-4">
                {new Array(COLS).fill(0).map((item, j) => {
                  return (
                    <div
                      key={'body-el-' + i + '-' + j}
                      className="flex-1 space-y-6 py-1">
                      <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="border-t border-gray-200 rounded-b-large w-full mx-auto">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1">
              <div className="space-y-6 p-4 text-left">
                <div className="h-2 bg-gray-300 rounded inline-block w-32"></div>
              </div>
            </div>
            <div className="flex-1">
              <div className="space-y-6 p-4 text-center">
                <div className="h-2 bg-gray-300 rounded inline-block w-32"></div>
              </div>
            </div>
            <div className="flex-1">
              <div className="space-y-6 p-4 text-right">
                <div className="h-2 bg-gray-300 rounded inline-block w-32"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingSkeletonTable;
