/* eslint-disable camelcase */

import {
  Avatar,
  Button,
  Caption,
  Card,
  EmptySearchResult,
  Filters,
  IndexTable,
  OptionList,
  Page,
  Pagination,
  SkeletonBodyText,
  SkeletonThumbnail,
  Stack,
  TextStyle,
  Tooltip,
  useIndexResourceState
} from '@shopify/polaris';
import {
  authState,
  categoriesState,
  productsState,
  toastState
} from '../atoms';
import { decodeEntities, htmlDecode, request } from '../utils';
import { useCallback, useEffect, useState } from 'react';

import { AuthInfo } from '../types/AuthInfo';
import DropKickTitleBar from '../components/DropKickTitleBar';
import ProductDetailsModal from '../components/ProductDetailsModal';
import ProductImportModal from '../components/ProductImportModal';
import StatusBadge from '../components/StatusBadge';
import { TableState } from '../types/TableState';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';

const PAGE_SIZE = 50;
const CATEGORIES_TIMEOUT = 1000 * 60 * 60 * 24; // 24hrs

const AvatarWrapperStyle = styled.div`
  padding: 0.5rem 1rem;
  .Polaris-Avatar,
  img {
    border-radius: 0px;
  }
  .Polaris-Avatar__Image {
    object-fit: contain;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
  }
`;

const IndexTableStyle = styled.div`
  @media (min-width: 28.625em) {
    .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell,
    .Polaris-IndexTable__TableCell--first {
      position: static !important;
    }
  }
`;

const FiltersContainerStyle = styled.div`
  .Polaris-Popover .Polaris-Popover__Section {
    padding: 0;
  }
`;

const Products = (): JSX.Element => {
  const [authInfo]: [AuthInfo, any] = useRecoilState(authState);
  const [categoriesInfo, setCategoriesInfo] =
    useRecoilState<any>(categoriesState);
  const [, setToast] = useRecoilState(toastState);
  const [productsInfo, setProductsInfo]: [TableState, any] =
    useRecoilState(productsState);

  // product details
  const [productModalProduct, setProductModalProduct] = useState<any>(null);
  const [productDetailsActive, setProductDetailsModalActive] = useState(false);

  // product import
  const [productImportModalActive, setProductImportModalActive] =
    useState(false);
  const [importConfig, setImportConfig] = useState<any>(null);

  // query
  const [query, setQuery] = useState<any>({
    search: '',
    exclusiveStartKey: null,
    pageSize: 50,
    categories: []
  });

  // filter values
  const [queryValue, setQueryValue] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [appliedFilters, setAppliedFilters] = useState<any>([]);

  // loading indicators
  const [loading, setLoading] = useState(true);
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true);

  // pagination related
  const [previousKeys] = useState<any>([{}]);
  const [page, setPage] = useState<number>(1);

  const resourceName = {
    singular: 'Product',
    plural: 'Products'
  };

  // this is the shopify polaris hook for handling selection
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(productsInfo.items);

  // handle when an applied filter is removed
  const handleRemoveFilter = useCallback(() => {
    setQuery({
      ...query,
      exclusiveStartKey: null,
      categories: [],
      search: queryValue.toLowerCase()
    });
    setAppliedFilters([]);
    setPage(1);
  }, [query, queryValue]);

  // handle when a category is changed
  const handleCategoryChange = useCallback((value) => {
    console.log('handleCategoryChange', value);
    // value = value.map((v) => v.value);
    setSelectedCategories(value);
    setSearchDisabled(false);
  }, []);

  const searchProducts = useCallback(
    async (force: boolean) => {
      // pull from cache if we are within the timeout
      // if (
      //   !force &&
      //   productsInfo.lastFetched > new Date().getTime() - PRODUCTS_TIMEOUT
      // ) {
      //   console.log(
      //     `got products from local CACHE. Expiration in ${
      //       (PRODUCTS_TIMEOUT +
      //         productsInfo.lastFetched -
      //         new Date().getTime()) /
      //       1000
      //     }s`
      //   );
      //   setLoading(false);
      //   return;
      // }
      setLoading(true);
      console.log('getting products...', query);
      const products: any = await request(
        'POST',
        'products',
        authInfo.auth,
        query
      );
      if (!products) {
        setToast({
          active: true,
          message: 'Error getting products',
          type: 'error'
        });
        setLoading(false);
        return;
      }
      console.log('got products', products);
      setProductsInfo({
        items: products.items.map((p) => {
          return {
            id: p.Product_Number,
            ...p
          };
        }),
        lastFetched: new Date().getTime()
      });
      setLoading(false);
    },
    [authInfo.auth, query, setProductsInfo, setToast]
  );

  const getCategories = useCallback(
    async (force?: boolean) => {
      // pull from cache if we are within the timeout
      if (
        !force &&
        categoriesInfo.lastFetched > new Date().getTime() - CATEGORIES_TIMEOUT
      ) {
        console.log(
          `got categories from local CACHE. Expiration in ${
            (CATEGORIES_TIMEOUT +
              categoriesInfo.lastFetched -
              new Date().getTime()) /
            1000
          }s`,
          categoriesInfo.items
        );
        setLoading(false);
        return;
      }

      const result = await request('GET', 'categories', authInfo.auth);
      if (!result) {
        setToast({
          active: true,
          message: 'Error getting categories',
          type: 'error'
        });
        setLoading(false);
        return;
      }
      setCategoriesInfo({
        items: result.categories,
        lastFetched: new Date().getTime()
      });

      console.log('got categories from API', result.categories);
    },
    [
      authInfo.auth,
      categoriesInfo.items,
      categoriesInfo.lastFetched,
      setCategoriesInfo,
      setToast
    ]
  );

  // format categories for the select component
  const categoryOptions = (categories) => {
    if (!categories) return [];

    // get all level 1 cats
    const parentCats = categories
      .filter((category: any) => {
        return category.level === 1;
      })
      .map((category: any) => {
        const sk = htmlDecode(category.SK);
        return {
          title: sk.split('#')[0]
        };
      });

    console.log('parentCats', parentCats);

    // get l2 cats
    const l2cats = parentCats.map((category: any) => {
      return {
        title: category.title,
        options: categories
          .filter((childCategory: any) => {
            return (
              childCategory.level === 2 &&
              htmlDecode(childCategory.SK).indexOf(category.title) === 0
            );
          })
          .map((childCategory: any) => {
            const sk = htmlDecode(childCategory.SK);
            return {
              value: childCategory.SK,
              label: sk.split('#')[1] + ` (${childCategory.count})`,
              count: childCategory.count
            };
          })
      };
    });

    return l2cats;
  };

  // execute a search when query changes
  useEffect(() => {
    getCategories();
    searchProducts(false);
  }, [getCategories, searchProducts]);

  // get the next page by calling the search function with the last item
  const handleNext = useCallback(() => {
    const lastProduct: any = productsInfo.items[productsInfo.items.length - 1];
    const newKey = {
      PK: lastProduct.PK,
      SK: lastProduct.SK,
      Product_Name: lastProduct.Product_Name
    };
    setQuery({
      ...query,
      exclusiveStartKey: newKey,
      previousKeys: [...previousKeys, newKey]
    });
    setPage(page + 1);
  }, [productsInfo, query, previousKeys, page]);

  // use the previous exclusive start key to go back
  const handlePrevious = useCallback(() => {
    const prevKey = query.previousKeys[query.previousKeys.length - 2];
    query.previousKeys.pop();
    setQuery({
      ...query,
      exclusiveStartKey: prevKey,
      previousKeys: [...query.previousKeys]
    });
    setPage(page - 1);
  }, [page, query]);

  // handle when the user types anything in the search field
  const handleQueryValueChange = useCallback((value) => {
    setQueryValue(value);
    setSearchDisabled(false);
  }, []);

  // handle when the user clicks the X in the search field to clear the search query
  const handleQueryValueRemove = useCallback(() => {
    // clear search and trigger refresh
    setQueryValue('');
    setSearchDisabled(false);
  }, []);

  const handleClearAll = useCallback(() => {
    setSelectedCategories([]);
    setAppliedFilters([]);
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  //
  const handleImportIntent = useCallback(() => {
    console.log('handleImportIntent', selectedResources, allResourcesSelected);

    if (allResourcesSelected) {
      setImportConfig({
        products: [],
        categories: query.categories,
        search: query.search,
        all: !query.categories.length && !query.search
      });
    } else {
      setImportConfig({
        products: productsInfo.items
          .filter((p: any) => selectedResources.includes(p.Product_Number))
          .map((p: any) => {
            return { PK: p.PK, SK: p.SK };
          }),
        categories: [],
        search: query.search,
        all: false
      });
    }
    setProductImportModalActive(true);
  }, [
    allResourcesSelected,
    productsInfo,
    query.categories,
    query.search,
    selectedResources
  ]);

  const promotedBulkActions = [
    {
      content: 'Import Products',
      onAction: handleImportIntent
    }
  ];

  // allow for enter to search
  const handleKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      handleSearchClick();
    }
  };

  const handleSearchClick = useCallback(() => {
    // commit changes to the query
    setQuery({
      ...query,
      categories: selectedCategories,
      search: queryValue
    });
    const newAppliedFilters: any = [];
    if (selectedCategories.length) {
      newAppliedFilters.push({
        key: 'categories',
        label:
          'Categories: ' +
          selectedCategories
            .map((c) => {
              c = htmlDecode(c);
              return c.split('#')[1];
            })
            .join('; '),
        onRemove: handleRemoveFilter
      });
    }
    setAppliedFilters(newAppliedFilters);
    setSearchDisabled(true);
    setPage(1);
  }, [query, queryValue, handleRemoveFilter, selectedCategories]);

  const filters = [
    {
      key: 'category',
      label: 'Category',
      hideClearButton: true,
      filter: (
        <OptionList
          onChange={handleCategoryChange}
          sections={categoriesInfo && categoryOptions(categoriesInfo.items)}
          selected={selectedCategories}
          allowMultiple
        />
      ),
      shortcut: true
    }
  ];

  const emptyStateMarkup = loading ? (
    [...Array(5)].map((_, index) => {
      return (
        <Card.Section key={index}>
          <Stack alignment="center">
            <Stack.Item>
              <SkeletonThumbnail size="small" />
            </Stack.Item>
            <Stack.Item fill>
              <SkeletonBodyText lines={1} />
            </Stack.Item>
          </Stack>
        </Card.Section>
      );
    })
  ) : (
    <EmptySearchResult
      title={'No products found'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );

  const formatCategoryString = (category1, category2, category3) => {
    return (
      decodeEntities(category1) +
      (!!category2 ? ' > ' + decodeEntities(category2) : '') +
      (!!category3 ? ' > ' + decodeEntities(category3) : '')
    );
  };

  const formatProductName = (productName: string) => {
    productName = htmlDecode(productName);
    if (productName.length > 64) {
      return (
        <Tooltip content={productName}>
          <TextStyle variation="strong">
            {productName.substring(0, 64) + '...'}
          </TextStyle>
        </Tooltip>
      );
    } else {
      return <TextStyle variation="strong">{productName}</TextStyle>;
    }
  };

  const showProductModal = useCallback(
    (productNumber) => {
      const product =
        productsInfo.items.find(
          (p: any) => p.Product_Number === productNumber
        ) || null;
      console.log('showProductModal', product);
      setProductModalProduct(product);
      setProductDetailsModalActive(true);
    },
    [productsInfo]
  );

  const rowMarkup =
    productsInfo &&
    productsInfo.items &&
    productsInfo.items.map(
      (
        {
          id,
          Product_Name,
          Product_Number,
          Thumbnail_Image,
          Category_1,
          Category_2,
          Category_3,
          Stock_Info,
          Suggested_Retail_Price,
          Wholesale_Price
        },
        index
      ) => (
        <IndexTable.Row
          id={id}
          key={Product_Number}
          selected={selectedResources.includes(Product_Number)}
          position={index}>
          <IndexTable.Cell>
            <AvatarWrapperStyle>
              <Avatar
                size="medium"
                name={Product_Name}
                source={Thumbnail_Image}
              />
            </AvatarWrapperStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div style={{ marginBottom: '2px' }}>
              {formatProductName(Product_Name)}
            </div>
            <Caption>
              <TextStyle variation="subdued">
                {formatCategoryString(Category_1, Category_2, Category_3)}
              </TextStyle>
            </Caption>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <StatusBadge status={Stock_Info} />
          </IndexTable.Cell>
          <IndexTable.Cell className="text-right">
            ${Wholesale_Price}
          </IndexTable.Cell>
          <IndexTable.Cell className="text-right">
            ${Suggested_Retail_Price}
          </IndexTable.Cell>
          <IndexTable.Cell className="text-right font-medium">
            <TextStyle variation="positive">
              ${(Suggested_Retail_Price - Wholesale_Price).toLocaleString()}
            </TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <button
              type="button"
              onClick={() => {
                showProductModal(Product_Number);
              }}
              className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              View Details
            </button>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    );

  return (
    <>
      <DropKickTitleBar title="Product Catalog" />
      <Page
        title=""
        fullWidth={true}
        subtitle="Search for products by name or select specific categories you would like to import into your store."
        primaryAction={{
          disabled: !selectedResources.length,
          content: 'Import',
          onAction: handleImportIntent
        }}>
        <Card>
          <div style={{ padding: '16px', display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <FiltersContainerStyle>
                <div onKeyDown={handleKeyPress}>
                  <Filters
                    disabled={loading}
                    queryValue={queryValue}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    onQueryChange={handleQueryValueChange}
                    onQueryClear={handleQueryValueRemove}
                    onClearAll={handleClearAll}>
                    <div
                      style={{ display: 'inline-block', width: '10px' }}></div>
                    <Button
                      primary
                      disabled={searchDisabled}
                      onClick={handleSearchClick}>
                      Search
                    </Button>
                  </Filters>
                </div>
              </FiltersContainerStyle>
            </div>
          </div>
          <>
            <IndexTableStyle>
              <IndexTable
                resourceName={resourceName}
                itemCount={productsInfo.items.length}
                selectedItemsCount={
                  allResourcesSelected
                    ? 'All'
                    : selectedResources
                    ? selectedResources.length
                    : 0
                }
                emptyState={emptyStateMarkup}
                onSelectionChange={handleSelectionChange}
                promotedBulkActions={promotedBulkActions}
                loading={loading}
                hasMoreItems={!(productsInfo.items.length < PAGE_SIZE)}
                headings={[
                  { title: 'Image' },
                  { title: 'Name' },
                  { title: 'In Stock' },
                  { title: 'Cost' },
                  { title: 'MSRP' },
                  { title: 'Profit' },
                  { title: 'Details' }
                ]}>
                {rowMarkup}
              </IndexTable>
            </IndexTableStyle>
            <div
              style={{
                padding: '16px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Pagination
                hasPrevious={previousKeys.length > 1 || page > 1}
                onPrevious={handlePrevious}
                hasNext={productsInfo.items.length === PAGE_SIZE}
                onNext={handleNext}
              />
            </div>
          </>
        </Card>

        {productImportModalActive && (
          <ProductImportModal
            importConfig={importConfig}
            dismiss={setProductImportModalActive}
            pageSize={productsInfo.pageSize}
          />
        )}

        {productDetailsActive && (
          <ProductDetailsModal
            dismiss={setProductDetailsModalActive}
            product={productModalProduct}
          />
        )}
        <br />
        <br />
      </Page>
    </>
  );
};

export default Products;
