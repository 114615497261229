import { Button, Card, Icon, Stack, TextStyle } from '@shopify/polaris';
import { CircleAlertMajor, CircleTickMajor } from '@shopify/polaris-icons';
import React, { useState } from 'react';

import { authState } from '../../atoms';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const STEP_NAME = 'import-product';

const ImportProductStatus = () => {
  const authInfo = useRecoilValue(authState);
  const [complete] = useState(
    authInfo.auth.welcomeTourStatus
      ? authInfo.auth.welcomeTourStatus.indexOf(STEP_NAME) >= 0
      : false
  );
  const buttonDisabled = authInfo.auth.welcomeTourStatus
    ? authInfo.auth.welcomeTourStatus.indexOf('review-terms') < 0
    : false;
  const history = useHistory();

  return (
    <>
      <Card.Subsection>
        <Stack wrap={false} alignment="center">
          <Stack.Item>
            {complete ? (
              <Icon source={CircleTickMajor} color="primary" />
            ) : (
              <Icon source={CircleAlertMajor} color="warning" />
            )}
          </Stack.Item>
          <Stack.Item>
            <div className="step-title">Import Your First Product</div>
            <div>
              {complete ? (
                <div className="step-subtitle">
                  <div className="step-message">
                    <TextStyle variation="positive">
                      Well done! You've imported a product!
                    </TextStyle>
                  </div>
                  <div className="step-button">
                    <Button
                      size="slim"
                      disabled={buttonDisabled}
                      onClick={() => history.push('/products')}>
                      Browse Products
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="step-subtitle">
                  <div className="step-message">
                    <TextStyle variation="subdued">
                      Select products to import to your store
                    </TextStyle>
                  </div>
                  <div className="step-button">
                    <Button
                      size="slim"
                      disabled={buttonDisabled}
                      onClick={() => history.push('/products')}>
                      Browse Products
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Stack.Item>
        </Stack>
      </Card.Subsection>
    </>
  );
};

export default ImportProductStatus;
