import {
  Card,
  DisplayText,
  Icon,
  Layout,
  Page,
  Stack,
  TextStyle
} from '@shopify/polaris';
import {
  GlobeMajor,
  PlayCircleMajor,
  QuestionMarkMajor
} from '@shopify/polaris-icons';

import AddPaymentInfoStatus from '../components/Dashboard/AddPaymentInfoStatus';
import { AuthInfo } from '../types/AuthInfo';
import DropKickTitleBar from '../components/DropKickTitleBar';
import ImportProductStatus from '../components/Dashboard/ImportProductStatus';
import React from 'react';
import ReviewTermsStatus from '../components/Dashboard/ReviewTermsStatus';
import { authState } from '../atoms';
import dropshipGraphic from '../assets/img/dropship.png';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

const DashboardLayout = styled.div`
  p {
    margin-bottom: 20px;
  }
  .step-title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
    color: #333;
  }
  .step-subtitle .step-message {
    margin-bottom: 8px;
  }
  .external-links {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 0.375rem;
    a {
      padding: 18px;
      border-radius: 0.375rem;
      margin-bottom: 12px;
      background: #eee;
      width: 100%;
      display: block;
      text-decoration: none;
      font-size: 14px;
      color: #666;
      line-height: 20px;
    }
    a:hover {
      background: #ddd;
    }
  }
  .Polaris-Layout__Section:first-child {
    flex: 1 1 14rem;
  }
`;

const NUMBER_STEPS = 3;

const Dashboard: React.FC = (): JSX.Element => {
  const authInfo: AuthInfo = useRecoilValue(authState);
  let completeStepCount = authInfo.auth.welcomeTourStatus
    ? JSON.parse(authInfo.auth.welcomeTourStatus).length
    : 0;
  if (completeStepCount > NUMBER_STEPS) {
    completeStepCount = NUMBER_STEPS;
  }
  return (
    <>
      <DropKickTitleBar title="Dashboard" />
      <Page title={'Dashboard'} titleHidden={true} fullWidth={false}>
        <>
          <br />
          <br />
          <Card sectioned>
            <DashboardLayout>
              <Stack>
                <Stack.Item></Stack.Item>
              </Stack>
              <Layout>
                <Layout.Section oneThird>
                  <DisplayText size="small">
                    Welcome to
                    <br />
                    {authInfo.app.name}
                  </DisplayText>
                  {completeStepCount >= NUMBER_STEPS ? (
                    <TextStyle variation="positive">
                      Great! You've completed your checklist successfully!{' '}
                    </TextStyle>
                  ) : (
                    <TextStyle variation="subdued">
                      Complete these simple steps to get ready to sell!
                    </TextStyle>
                  )}
                  <br />
                  <br />
                  <TextStyle variation="positive">
                    <DisplayText size="large">
                      {completeStepCount}/{NUMBER_STEPS}
                    </DisplayText>
                  </TextStyle>
                  <br />
                  <br />
                  <img src={dropshipGraphic} alt="DropShipping Made Easy" />
                </Layout.Section>
                <Layout.Section oneThird>
                  <DisplayText size="small">Get Started</DisplayText>
                  <ReviewTermsStatus />
                  <AddPaymentInfoStatus />
                  <ImportProductStatus />
                </Layout.Section>
                <Layout.Section oneThird>
                  <div className="external-links">
                    <DisplayText size="small">Helpful Links</DisplayText>
                    <p>More help to kickstart your store!</p>
                    <a
                      href={authInfo.app.videoUrl}
                      target="_blank"
                      rel="noreferrer">
                      <Stack wrap={false} alignment="center">
                        <Stack.Item>
                          <Icon source={PlayCircleMajor} color="primary" />
                        </Stack.Item>
                        <Stack.Item>
                          <TextStyle variation="strong">
                            Learn How It Works
                          </TextStyle>
                          <div>
                            <TextStyle variation="subdued">
                              Watch a short intro video
                            </TextStyle>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </a>
                    <a
                      href={authInfo.app.faqUrl}
                      target="_blank"
                      rel="noreferrer">
                      <Stack wrap={false} alignment="center">
                        <Stack.Item>
                          <Icon source={QuestionMarkMajor} color="primary" />
                        </Stack.Item>
                        <Stack.Item>
                          <TextStyle variation="strong">
                            Have Questions?......
                          </TextStyle>
                          <div>
                            <TextStyle variation="subdued">
                              Get answers in our FAQ
                            </TextStyle>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </a>
                    <a
                      href={authInfo.app.websiteUrl}
                      target="_blank"
                      rel="noreferrer">
                      <Stack wrap={false} alignment="center">
                        <Stack.Item>
                          <Icon source={GlobeMajor} color="primary" />
                        </Stack.Item>
                        <Stack.Item>
                          <TextStyle variation="strong">
                            Want to Know More?
                          </TextStyle>
                          <div>
                            <TextStyle variation="subdued">
                              Learn more about us
                            </TextStyle>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </a>
                  </div>
                </Layout.Section>
              </Layout>
            </DashboardLayout>
          </Card>
        </>
        <br />
        <br />
        <br />
        <br />
      </Page>
    </>
  );
};

export default Dashboard;
