/* eslint-disable camelcase */

import { App } from './types/App';
import { Auth } from './types/Auth';
import { AuthInfo } from './types/AuthInfo';
import { TableState } from './types/TableState';
import { Toast } from './types/Toast';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const authState = atom({
  key: 'authInfo',
  default: {
    auth: {} as Auth,
    app: {} as App,
    timeout: 0
  } as AuthInfo,
  effects_UNSTABLE: [persistAtom]
});

export const toastState = atom({
  key: 'toast',
  default: {
    active: false,
    message: '',
    type: ''
  } as Toast
});

export const productsState = atom({
  key: 'products',
  default: {
    items: [] as any,
    pageSize: 50,
    search: '',
    prevExclusiveStartKeys: [] as any[],
    timeout: 1000 * 60 * 60 * 24 // 1d
  } as TableState,
  effects_UNSTABLE: [persistAtom]
});

export const categoriesState = atom({
  key: 'categories',
  default: {
    items: [] as any,
    pageSize: 50,
    search: '',
    prevExclusiveStartKeys: [] as any[],
    timeout: 1000 * 60 * 60 * 24 // 1d
  } as TableState,
  effects_UNSTABLE: [persistAtom]
});

export const importsState = atom({
  key: 'imports',
  default: {
    items: [] as any,
    pageSize: 50,
    search: '',
    prevExclusiveStartKeys: [] as any[],
    timeout: 1000 * 60 * 5 // 5m
  } as TableState
});

export const ordersState = atom({
  key: 'orders',
  default: {
    items: [] as any,
    pageSize: 50,
    search: '',
    prevExclusiveStartKeys: [] as any[],
    timeout: 1000 * 60 * 5 // 5m
  } as TableState
});
