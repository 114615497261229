import { Page, TextStyle } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { authState, importsState, toastState } from '../atoms';
import { convertSecondsToHHMMSS, request } from '../utils';
import { useRecoilState, useRecoilValue } from 'recoil';

import DropKickTitleBar from '../components/DropKickTitleBar';
import LoadingSkeletonTable from '../components/LoadingSkeletonTable';
import NoRecordsFound from '../components/NoRecordsFound';
import { PageChangeType } from '../types/Pagination';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import StatusBadge from '../components/StatusBadge';
import { TableState } from '../types/TableState';

const Imports: React.FC = (): JSX.Element => {
  const authInfo = useRecoilValue(authState);
  const [, setToast] = useRecoilState(toastState);
  const [loading, setLoading] = useState(true);
  const [importsInfo, setImportsInfo]: [TableState, any] =
    useRecoilState(importsState);
  const [refreshCount, setRefreshCount] = useState(0);

  // todo - implement pagination
  const getImports = useCallback(
    async (force: boolean) => {
      // pull from cache if we are within the timeout
      // if (
      //   !force &&
      //   importsInfo.lastFetched > new Date().getTime() - IMPORTS_TIMEOUT
      // ) {
      //   console.log(
      //     `got imports from local CACHE. Expiration in ${
      //       (IMPORTS_TIMEOUT + importsInfo.lastFetched - new Date().getTime()) /
      //       1000
      //     }s`
      //   );
      //   setLoading(false);
      //   return;
      // }
      setLoading(true);
      const result = await request('POST', 'imports', authInfo.auth, {
        pageSize: importsInfo.pageSize,
        search: importsInfo.search,
        exclusiveStartKey: importsInfo.lastEvaluatedKey
      });
      if (!result) {
        setToast({
          active: true,
          message: 'Error getting imports',
          type: 'error'
        });
        setLoading(false);
        return;
      }

      const newImportsInfo = {
        ...importsInfo,
        items: result.items,
        lastEvaluatedKey: result.lastEvaluatedKey,
        lastFetched: new Date().getTime()
      };
      setImportsInfo(newImportsInfo);
      setLoading(false);
    },
    [
      (authInfo.auth,
      importsInfo.pageSize,
      importsInfo.exclusiveStartKey,
      importsInfo.search,
      setImportsInfo,
      refreshCount,
      setToast)
    ]
  );

  // todo - test this
  const handleCancel = useCallback(
    (index: any) => {
      try {
        (async () => {
          const importRecords: any[] = [...importsInfo.items];
          const importRecord: any = importRecords[index];
          let selectedImportRecord = { ...importRecord };
          selectedImportRecord.loading = true;
          importRecords[index] = selectedImportRecord;
          setImportsInfo({
            ...importsInfo,
            items: [...importRecords]
          });
          console.log('selectedImportRecord', importRecord);
          const result = await request('POST', 'imports', authInfo.auth, {
            importSK: importRecord.SK,
            action: 'cancel'
          });
          if (!result) {
            setToast({
              active: true,
              message: 'Error Cancelling Import',
              type: 'error'
            });
            return;
          }
          selectedImportRecord = { ...importRecord };
          selectedImportRecord.status = 'Cancelled';
          selectedImportRecord.loading = false;
          importRecords[index] = selectedImportRecord;
          setImportsInfo({
            ...importsInfo,
            items: [...importRecords]
          });
        })();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [authInfo.auth, importsInfo, setImportsInfo, setToast]
  );

  const handlePageSizeChange = (newSize) => {
    console.log('changing page size to', newSize);
    setImportsInfo({
      ...importsInfo,
      prevExclusiveStartKeys: [],
      lastEvaluatedKey: null,
      exclusiveStartKey: null,
      pageSize: newSize
    });
  };

  const handleChangePage = (type: PageChangeType) => {
    switch (type) {
      case 'next':
        console.log(
          'changing exclusiveStartKey to',
          importsInfo.lastEvaluatedKey
        );
        setImportsInfo({
          ...importsInfo,
          exclusiveStartKey: importsInfo.lastEvaluatedKey,
          prevExclusiveStartKeys: importsInfo.exclusiveStartKey
            ? [...importsInfo.prevExclusiveStartKeys].concat([
                importsInfo.exclusiveStartKey
              ])
            : []
        });
        break;
      case 'prev':
        if (importsInfo.prevExclusiveStartKeys.length > 0) {
          setImportsInfo({
            ...importsInfo,
            exclusiveStartKey:
              importsInfo.prevExclusiveStartKeys[
                importsInfo.prevExclusiveStartKeys.length - 1
              ],
            lastEvaluatedKey:
              importsInfo.prevExclusiveStartKeys[
                importsInfo.prevExclusiveStartKeys.length - 1
              ],
            prevExclusiveStartKeys: [
              ...importsInfo.prevExclusiveStartKeys
            ].slice(0, -1)
          });
        } else {
          setImportsInfo({
            ...importsInfo,
            exclusiveStartKey: null,
            lastEvaluatedKey: null,
            prevExclusiveStartKeys: []
          });
        }

        break;
    }
  };

  const handleRefresh = () => {
    // reset to first page
    setImportsInfo({
      ...importsInfo,
      prevExclusiveStartKeys: [],
      lastEvaluatedKey: null,
      exclusiveStartKey: null
    });
    setRefreshCount(refreshCount + 1);
  };

  // get orders from server or cache
  useEffect(() => {
    getImports(false);
  }, [getImports]);

  const formatImports = (imports) => {
    return imports.map((item: any, index: number) => {
      const start = new Date(item.start);
      const end = new Date(item.end);
      const status: string = item.status || '';
      const duration = item.end
        ? convertSecondsToHHMMSS((end.getTime() - start.getTime()) / 1000)
        : convertSecondsToHHMMSS(
            (new Date().getTime() - start.getTime()) / 1000
          );
      return (
        <tr
          key={'order-' + index}
          className={
            'hover:bg-gray-100 ' + (index % 2 === 0 ? undefined : 'bg-gray-50')
          }
        >
          <td className="whitespace-nowrap py-4 pl-3 text-left text-sm sm:pr-6 text-gray-500">
            {start.toLocaleDateString() + ' ' + start.toLocaleTimeString()}
          </td>
          <td className="whitespace-nowrap py-4 pl-3 text-left text-sm sm:pr-6 text-gray-500">
            {item.end
              ? end.toLocaleDateString() + ' ' + end.toLocaleTimeString()
              : null}
          </td>
          <td className="whitespace-nowrap py-4 pl-3 text-left text-sm sm:pr-6 text-gray-500">
            <TextStyle variation="code">{duration}</TextStyle>
          </td>
          <td className="whitespace-nowrap py-4 pl-3 text-left text-sm sm:pr-6 text-gray-500">
            <StatusBadge status={status} />
          </td>
          <td className="whitespace-nowrap py-4 pl-3 text-right text-sm sm:pr-6 text-gray-500">
            {item.total}
          </td>
          <td className="whitespace-nowrap py-4 pl-3 text-left text-sm sm:pr-6 text-gray-500">
            <a
              target="_blank"
              className="mr-2 font-medium text-blue-600 hover:text-blue-700 hover:underline"
              href={`https://${
                item.shopDomain
              }/admin/products?selectedView=all&tag=${item.SK.split('#')[1]}`}
              rel="noreferrer"
            >
              View
            </a>
            {item.loading && <Spinner className="w-4 h-4 inline mx-2" />}
            {(item.status === 'In Progress' ||
              item.status === 'Not Started' ||
              item.status === 'Ready') &&
              !item.loading && (
                <button
                  className="mr-2 font-medium text-blue-600 hover:text-blue-700 cursor-pointer"
                  onClick={() => handleCancel(index)}
                >
                  Cancel
                </button>
              )}
          </td>
          {authInfo.auth.admin && (
            <>
              <td className="whitespace-nowrap py-4 pl-3 text-left text-sm sm:pr-6 text-gray-500">
                {item.SK.split('#')[1]}
              </td>
              <td className="whitespace-nowrap py-4 pl-3 text-left text-sm sm:pr-6 text-gray-500">
                {item.shopDomain}
                <br />
                {item.SK.split('#')[0]}
              </td>
            </>
          )}
        </tr>
      );
    });
  };

  return (
    <>
      <DropKickTitleBar title="Imports" />
      <Page
        title={'Imports'}
        titleHidden={true}
        fullWidth={true}
        subtitle={
          'This page displays all product imports that have been executed along with their respective status.'
        }
      >
        <div className="mt-6 mb-8 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700"></p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={handleRefresh}
              className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </button>
          </div>
        </div>
        {loading ? (
          <LoadingSkeletonTable />
        ) : (
          <>
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-visible sm:-mx-6 lg:-mx-8">
                <div className=" inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 pb-200">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300 z-0">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Start
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold sm:pr-6 text-gray-900"
                          >
                            End
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold sm:pr-6 text-gray-900"
                          >
                            Duration
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold sm:pr-6 text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                          >
                            Products
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            View
                          </th>
                          {authInfo.auth.admin && (
                            <>
                              <th
                                scope="col"
                                className="px-3 py-3.5 bg-yellow-50 text-left text-sm font-semibold text-gray-900"
                              >
                                ImportId
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 bg-yellow-50 text-left text-sm font-semibold text-gray-900"
                              >
                                Domain
                              </th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {formatImports(importsInfo.items)}
                      </tbody>
                    </table>
                    {importsInfo.items.length === 0 && <NoRecordsFound />}
                    <Pagination
                      entityInfo={importsInfo}
                      handlePageSizeChange={handlePageSizeChange}
                      handleChangePage={handleChangePage}
                      location="footer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </Page>
    </>
  );
};

export default Imports;
